import * as React from "react"
import Icon1 from "../assets/img/icons/content.svg"
import Icon2 from "../assets/img/icons/smartphone-approve.svg"
import Icon3 from "../assets/img/icons/performance-macbook.svg"
import Icon4 from "../assets/img/icons/web-accessibility.svg"
import Icon6 from "../assets/img/icons/user.svg"
import Icon7 from "../assets/img/icons/robot-2.svg"
import MyLink from "../components/link"
import Introduction from "../components/intro"
import ContactForm from "../components/contactForm"
import Process from "../components/process"
import GetInTouch from "../components/getInTouch"
import SubNav from "../components/subNav"
import Artwork from "../assets/img/artwork/artwork-07.svg"
import processJson from "../json/processes/search-engine-optimisation"

export default function Page() {
    return (
        <div className="service-page">
            <Introduction Artwork={Artwork}>
                <h1><span>Search</span> Engine Optimisation.</h1>
                <p className="text-larger"><strong>Boost your organic search result ranking with a perfectly optimised website.</strong></p>
            </Introduction>

            <SubNav
                title="Search Engine Optimisation"
                nav={['Factors that influence SEO', 'Our Process', 'Get in Touch']}
            />

            <div className="container">
                
                <h3 id="introduction">Introduction</h3>
                <p>Search Engine Optimisation (SEO) is the process of obtaining a higher position in the <strong>Google organic search results</strong>. These are the results that you see underneath the paid adverts. This is done by improving your site to increase its visibility for relevant searches.</p>
                
                <h3 id="how-it-works">How it Works</h3>
                <p>When someone performs a search, the search engines scour their indexes for <strong>highly relevant content</strong> then orders these in hopes of solving the searchers query. The <strong>higher a website ranks in this search, the more relevant the search engines believes their content is the the query</strong>.</p>
                <p>"Crawling" is a discovery process where Google send out a team of robots (also know as spiders or crawlers) to find new content. The exist to discover, understand and organise the internets content to offer relevant results to searchers. So when your <strong>content is fresh and up-to-date</strong>, your website will have a better chance of ranking higher.</p>
                <p>This is done by placing <strong>keywords and phrases</strong> in your sites content or headings to make it possible to <strong>find your site</strong> via search engines. It may also involve changes to your sites layout and design.</p>
                <p>You need to know <strong>how people are searching for products/services in order to make it easy to find you</strong>, or they will be going to your competitors. </p>
            <div className="dashed my-20"></div>

                <h3 id="Factors the influence your SEO">Factors that influence your SEO</h3>

                <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-6">
                    <div className="mt-8">
                        <Icon1  />
                        <h3 className="cl-primary mt-5">Content</h3>
                        <p>This huge role in how Google sees your site. Ensure that your content is fresh, relevant, unique, accurate. As well as also being loaded in a logical order, as this also helps your visitors find related content easily.</p>
                    </div>
                    <div className="mt-8">
                        <Icon2 />
                        <h3 className="cl-primary mt-5">Mobile friendliness</h3>
                        <p>With over 50% of all internet searches being performed on mobile, you don't want to miss out! Make sure your website is optimised to work on these devices. Google favours mobile friendly sites.</p>
                    </div>
                    <div className="mt-8">
                        <Icon3 />
                        <h3 className="cl-primary mt-5">Page Speed & Performance</h3>
                        <p>We all know how quickly we loose interest in a website that is slow to load, ensure that you website remains as fast a possible.</p>
                    </div>
                    <div className="mt-8">
                        <Icon4 />
                        <h3 className="cl-primary mt-5">Accessibility</h3>
                        <p>Your website needs to be accessible for everyone.  Perhaps they are on a moving train, have poor internet connectivity, can't play loud audio or are using screen-readers.</p>
                    </div>
                    <div className="mt-8">
                        <Icon6 />
                        <h3 className="cl-primary mt-5">User Experience</h3>
                        <p>Is your website easy to navigate? If not, you might be loosing out on potential customer. No one wants to spend ages trying to find a page or product.</p>
                    </div>
                    <div className="mt-8">
                        <Icon7 />
                        <h3 className="cl-primary mt-5">Technical factors</h3>
                        <p>These include your sitemap, robots file, alt tags, and https certificate, to name a few.</p>
                    </div>
                </div>

            </div>

            {/* Our Process */}
            <div className="component vh-100 gradient-right-blue pb-32">
                <h2 className="text-center mb-0">Our Process<span className="symbol symbol-green"><span></span></span></h2>
                <Process data={processJson} />


            <p>We will give our recommendations for all of the above, the keywords and phrases, image alt tags, page descriptions, and possible fresh relevant content ideas to you to be implemented on your site.</p>
            </div>
            
            <div className="dashed my-20"></div>
            <h2>Pricing</h2>
            <p>We cater to all different kinds of websites and budgets. Our fees are structured on an hourly rate. The amount of hours billed will depend on how much work is required to optimise the site to obtain the client's goal.</p>
            <p>The first few months will require more hours due to the research needed to find the best possible keywords for your brand, make changes and monitor the sites activity and rating. Thereafter the monthly hours can be reduced to maintain the sites ranking. </p>
            <p>Should there be any redesigns of the site or updating of content that requires SEO assistance, additional hours may be required.</p>
            <p>Contact us to find out more about how we can assist you!</p>
            <div className="dashed my-20"></div>

            {/* Get in Touch */}
            <div className="component vh-100 gradient-left-purple pb-32">
                <h2 className="text-center mb-0">Get in Touch<span className="symbol symbol-purple"><span></span></span></h2>
                <div className="container flex flex-wrap pt-20 justify-center">
                    <div className="w-full md:w-4/12">
                        <ContactForm
                            title="Have any questions?"
                            description='<a href="mailto:info@artificial.studio">Email us</a> or complete the form below and we’ll get back to you ASAP.'
                        />
                    </div>
                    <div className="w-full md:w-2/12">
                        <div className="dashed-vertical"></div>
                    </div>
                    <div className="w-full md:w-4/12">
                        <div className="card">
                            <h3 className="cl-green">Need a more reliable hosting solution?</h3>
                            <p><MyLink to="/hosting-services/" className="btn">View our hosting packages</MyLink></p>
                        </div>
                        <div className="card mt-8">
                            <h3 className="cl-green">Brand new website?</h3>
                            <p><MyLink to="/domain-registration-and-management/" className="btn">Find the perfect domain</MyLink></p>
                        </div>
                    </div>
                </div>
            </div>

            <GetInTouch />
        </div>
    )
}
